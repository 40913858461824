import Vue from 'vue'
import Router from 'vue-router'
import ConcoursList from './views/ConcoursList.vue'
import Concours from './views/Concours.vue'
import Epreuve from './components/Epreuve.vue'
import EpreuvesList from './components/EpreuvesList.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'ConcoursList',
            component: ConcoursList
        },
        {
            path: '/:num',
            name: 'Concours',
            component: Concours,
            children: [
                {
                    path: 'epreuve/:ep',
                    component: Epreuve
                },
                {
                    path: '',
                    component: EpreuvesList
                }
            ]

        }
    ]
})
