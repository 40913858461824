<template>
    <div class="concours">

        <div>
            <img width="100%" src="img/logo.svg" alt="Equilibre">
        </div>

        <div class="card" v-for="conc in concours" @click="openConcours(conc.num)"
             :class="{'active': conc.num === selected}">

            <div class="content">
                <div class="name">
                    <div class="discipline"
                         :style="{'background-image': 'url(\'icon/discipline/' + conc.description.nom_discipline +'.png\')'}">
                    </div>
                    <span>{{conc.description.nom}}</span>
                </div>
                <div>
                    <label>Organisateur:</label> <br>{{conc.description.organisateur}}
                </div>
                <div v-if="conc.description.juge">
                    <label>Président de jury:</label> <br>{{conc.description.juge}}
                </div>
                <div>
                    <label>Numéro:</label> <br>{{conc.description.num}}
                </div>

            </div>
            <div class="infos">
                <span>Mis à jour le {{conc.last_update | date('LLL')}}</span>
            </div>
        </div>


    </div>
</template>

<script>


    export default {
        name: 'home',
        computed: {
            concours() {
                return this.$store.getters.concoursList;
            },
            selected() {
                let conc = this.$store.getters.concours;
                if (conc) {
                console.log(conc);

                    return conc.json.ffe.concours[0].$.num;
                }
            }
        },
        methods: {
            openConcours(num) {
                this.$router.push(num)
            }
        },

        mounted() {
            this.$store.dispatch('loadConcList');
        }
    }
</script>

<style lang="scss">

    @import "../variables";
    .concours {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        //min-height: 100vh;
        .name {
            display: flex;
            align-items: center;
            flex-direction: row;
            margin-bottom: 1rem;
        }

        label {
            font-size: 0.7em;
            //opacity: 0.8;
        }


        .card {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
            width: 100vw;
            margin-top: 1rem;
            padding: 16px;
            box-sizing: border-box;
            &.active{
                border-right: solid 4px #6876ff; ;
            }

        }
    }

    .discipline {
        height: 3rem;
        width: 3rem;
        margin-right: 1rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
    }
</style>
