<template>
    <div>
        <div class="toast" v-if="showToast">
            <div class="message">Impossible de charger le concours. <br><br>Avez-vous internet ?</div>
            <router-link class="btn" tag="button" to="/">Changer de concours</router-link>
            <button class="btn" @click="loadConc()" :disabled="loader">Réessayer</button>
        </div>
        <div class="loader" v-if="loader|| showToast">
            <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'loader',
        computed: {
            loader() {
                return this.$store.getters.loader;
            },
            showToast() {
                return this.$store.state.errorConnect;
            }
        },
        methods: {
            loadConc() {
                if (this.$route.params.num) {
                    this.$store.dispatch('loadConc', this.$route.params.num);//TODO refresh list conc

                } else {

                    this.$store.dispatch('loadConcList');//TODO refresh list conc
                }
            }
        }
    }

</script>

<style lang="scss">
    @import "../variables";


    .toast {
        position: fixed;
        bottom: 0;
        padding: 10px;
        border-radius: 5px 5px 0 0;
        background-color: white;
        left: 10px;
        right: 10px;
        z-index: $header-z-index + 3;
        text-align: center;

        .message {
            margin-bottom: 10px;
        }


    }


    //loader
    .loader {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        display: flex;
        align-items: center;
        height: 100vh;
        width: 100vw;
        z-index: $header-z-index +1;
        background-color: rgba(104, 118, 255, 0.8);

        .sk-folding-cube {
            margin: 20px auto;
            width: 40px;
            height: 40px;
            position: relative;
            -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
        }

        .sk-folding-cube .sk-cube {
            float: left;
            width: 50%;
            height: 50%;
            position: relative;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }

        .sk-folding-cube .sk-cube:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
            -webkit-transform-origin: 100% 100%;
            -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
        }

        .sk-folding-cube .sk-cube2 {
            -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);
        }

        .sk-folding-cube .sk-cube3 {
            -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);
        }

        .sk-folding-cube .sk-cube4 {
            -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);
        }

        .sk-folding-cube .sk-cube2:before {
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }

        .sk-folding-cube .sk-cube3:before {
            -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
        }

        .sk-folding-cube .sk-cube4:before {
            -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
        }

        @-webkit-keyframes sk-foldCubeAngle {
            0%, 10% {
                -webkit-transform: perspective(140px) rotateX(-180deg);
                transform: perspective(140px) rotateX(-180deg);
                opacity: 0;
            }
            25%, 75% {
                -webkit-transform: perspective(140px) rotateX(0deg);
                transform: perspective(140px) rotateX(0deg);
                opacity: 1;
            }
            90%, 100% {
                -webkit-transform: perspective(140px) rotateY(180deg);
                transform: perspective(140px) rotateY(180deg);
                opacity: 0;
            }
        }

        @keyframes sk-foldCubeAngle {
            0%, 10% {
                -webkit-transform: perspective(140px) rotateX(-180deg);
                transform: perspective(140px) rotateX(-180deg);
                opacity: 0;
            }
            25%, 75% {
                -webkit-transform: perspective(140px) rotateX(0deg);
                transform: perspective(140px) rotateX(0deg);
                opacity: 1;
            }
            90%, 100% {
                -webkit-transform: perspective(140px) rotateY(180deg);
                transform: perspective(140px) rotateY(180deg);
                opacity: 0;
            }
        }
    }


</style>