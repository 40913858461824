<template>
    <div class="epreuve">
        <content>

            <div class="row">
                <div>





                </div>
                <p v-if="param">
                    {{param.nom}}
                    <button class="btn-icon" @click="showBareme = !showBareme" title="Afficher le detail du barème">
                        <img src="icon/info.svg">
                    </button>
                </p>
                <div v-if="showBareme">


                    <div v-for="manche in param.epreuve[0].manche">
                        <strong v-if="!manche.$.nom"> Manche {{manche.$.num}}:</strong>
                        <strong v-if="manche.$.nom"> {{manche.$.nom}}:</strong>

                        <span v-for="score in manche.score">{{score.$.nom}}: {{epreuve.bareme[epreuve.bareme.id].score[manche.$.num][score.$.num]}}
                                          <span
                                                  v-if="score.$.unite == 'T'">s</span>&nbsp;&nbsp;&nbsp;</span><br>

                    </div>
                </div>
            </div>


            <div class="row" v-if="engas.length > 0">
                <div style="width: 100%; text-align: right; opacity: 0.7; font-size: 0.8em"><span v-if="restant>0">encore {{restant}} /</span>{{engas.length}} partant(s)</div>
                <div class="progress"><div class="bar" :style="{width: progress + '%'}"></div></div>
                <table class="table">

                    <tr v-if="param" v-show="options.afficheDetail">

                        <th v-if="!isEquipe" colspan="5"></th>
                        <th v-if="isEquipe" colspan="4"></th>
                        <th v-if="param.manche" v-for="manche in param.manche"
                            :colspan="manche.score.length">
                            <span v-if="!manche.$.nom"> Manche {{manche.$.num}}:</span>
                            <span v-if="manche.$.nom">{{manche.$.nom}}</span>

                        </th>
                    </tr>
                    <tr>
                        <th v-show="options.classementPosition" style="text-align: center">Clt</th>
                        <th>Dos</th>
                        <th v-if="!isEquipe">Cavaliers</th>
                        <!--<th v-if="!isEquipe">Cheval</th>-->
                        <th v-if="isEquipe">Equipe</th>
                        <!--<th>Club</th>-->


                        <th v-show="!options.classementPosition" style="text-align: center">Clt</th>
                        <th v-show="options.afficheDetail" v-for="scoreTitle in scoreList">
                            {{scoreTitle.score.nom}}
                        </th>
                    </tr>
                    <tr v-if="!isEquipe"
                        v-for="e in engagementOrder">
                        <td v-show="options.classementPosition" style="text-align: center">
                            <img src="icon/first.svg" v-if="e.classement == 1 ">
                            <img src="icon/second.svg"
                                 v-if="e.classement == 2 && e.classement <= nbClasse">
                            <img src="icon/third.svg"
                                 v-if="e.classement == 3 && e.classement <= nbClasse">
                            <span v-if="!(e.classement == 1 || (e.classement == 2 && e.classement <= nbClasse) || (e.classement == 3 && e.classement <= nbClasse))">{{e.classement}}</span>
                            <img src="icon/badge.svg"
                                 v-if="e.classement && e.classement <= nbClasse && e.classement > 3">
                        </td>
                        <td class="text-center">{{e.$.dossard}}</td>
                        <td>
                            <table>
                                <tr style="background-color: inherit!important;">
                                    <td style="font-weight:600;">{{e.cavalier[0].$.prenom}} {{e.cavalier[0].$.nom}}</td>
                                </tr>
                                <tr style="background-color: inherit!important;">
                                    <td>{{e.equide[0].$.nom}}</td>

                                </tr>
                                <tr style="opacity: 0.7; font-size: 0.8em; background-color: inherit!important;">
                                    <td colspan="2">{{e.club[0].$.nom}}</td>
                                </tr>
                            </table>
                        </td>
                        <td v-show="!options.classementPosition" style="text-align: center">
                            <img alt="1" class="classement" src="icon/first.svg"
                                 v-if="e.classement == 1 ">
                            <img alt="2" class="classement" src="icon/second.svg"
                                 v-if="e.classement == 2 && e.classement <= nbClasse">
                            <img alt="3" class="classement" src="icon/third.svg"
                                 v-if="e.classement == 3 && e.classement <= nbClasse">
                            <span v-if="!(e.classement == 1 || (e.classement == 2 && e.classement <= nbClasse) || (e.classement == 3 && e.classement <= nbClasse))">{{e.classement}}</span>
                            <img alt="R" class="classement" src="icon/badge.svg"
                                 v-if="e.classement && e.classement <= nbClasse && e.classement > 3">
                        </td>

                        <template v-if="options.afficheDetail" v-for="scoreTitle in scoreList">
                            <td>
                                        <span v-if="e.detail && e.detail[scoreTitle.manche] && e.detail[scoreTitle.manche][scoreTitle.score.num]">
                                        {{e.detail[scoreTitle.manche][scoreTitle.score.num]}}
                                        </span>
                            </td>
                        </template>


                    </tr>

                    <tr v-if="isEquipe"
                        v-for="e in equipeOrder">
                        <td v-show="options.classementPosition" style="text-align: center">
                            <img alt="1" class="classement" src="icon/first.svg"
                                 v-if="e.classement == 1 ">
                            <img alt="2" class="classement" src="icon/second.svg"
                                 v-if="e.classement == 2 && e.classement <= nbClasse">
                            <img alt="3" class="classement" src="icon/third.svg"
                                 v-if="e.classement == 3 && e.classement <= nbClasse">
                            <span v-if="!(e.classement == 1 || (e.classement == 2 && e.classement <= nbClasse) || (e.classement == 3 && e.classement <= nbClasse))">{{e.classement}}</span>
                            <img alt="R" class="classement" src="icon/badge.svg"
                                 v-if="e.classement && e.classement <= nbClasse && e.classement > 3">
                        </td>
                        <td class="text-center">{{e.$.dossard}}</td>
                        <td v-if="isEquipe">
                            {{e.$.nom}}<br><br>
                            <span v-show="options.afficheDetail" v-for="mem in e.membre">
                                        {{mem.cavalier[0].$.prenom}} {{mem.cavalier[0].$.nom}} & {{mem.equide[0].$.nom}}<br>
                                            </span>
                        </td>
                        <td>{{e.club[0].$.nom}}</td>
                        <td v-show="!options.classementPosition" style="text-align: center">
                            <img alt="1" src="icon/first.svg"
                                 v-if="e.classement == 1 ">
                            <img alt="2" src="icon/second.svg"
                                 v-if="e.classement == 2 && e.classement <= nbClasse">
                            <img alt="3" src="icon/third.svg"
                                 v-if="e.classement == 3 && e.classement <= nbClasse">
                            <span v-if="!(e.classement == 1 || (e.classement == 2 && e.classement <= nbClasse) || (e.classement == 3 && e.classement <= nbClasse))">{{e.classement}}</span>
                            <img alt="R" src="icon/badge.svg"
                                 v-if="e.classement && e.classement <= nbClasse && e.classement > 3">
                        </td>
                        <template v-if="options.afficheDetail" v-for="scoreTitle in scoreList">
                            <td class="details">
                                        <span v-if="e.detail && e.detail[scoreTitle.manche] && e.detail[scoreTitle.manche][scoreTitle.score.num]">
                                        {{e.detail[scoreTitle.manche][scoreTitle.score.num]}}
                                        </span>
                            </td>
                        </template>

                    </tr>


                </table>

            </div>
            <div v-else class="empty">
                <img src="img/logo-small-grey.svg" width="70%" alt=""><br>
                Aucun engagé dans cette épreuve
                <router-link to="../" tag="button" class="btn">Changer d'épreuve</router-link>
            </div>
        </content>
    </div>

</template>

<script>


    let scoreListFn = function (bar) {
        let list = [];
        for (let i = 0; i < bar.manche.length; i++) {
            let manche = bar.manche[i];
            for (let j = 0; j < manche.score.length; j++) {
                let score = manche.score[j];
                let obj = {
                    manche: manche.$.num,
                    score: score.$
                };
                list.push(obj);
            }
        }
        return list;
    };
    let classementFn = function (couple) {
        if (couple.classement) {
            let cl = parseInt(couple.classement);
            if (!isNaN(cl)) {

                return cl
            } else {
                return 9999;
            }
        } else {
            let dos = parseInt(couple.$.dossard);
            return 8999 + dos;
        }

    };


    export default {
        data() {
            return {
                showBareme: false,
            }
        },
        computed: {
            epreuve() {
                return this.$store.getters.epreuve(this.$route.params.ep)
            },
            engas(){
                    let engas = this.epreuve.engagement ?  this.epreuve.engagement : this.epreuve.equipe;
                    if(!engas) return [];
                    return engas
            },
            restant(){
                    return this.engas.filter(enga => !enga.classement).length;
            },
            progress(){
                    return this.engas.filter(enga => enga.classement).length *100 / this.engas.length ;
            },

            nbClasse() {
                return Math.ceil(this.engas.length / 4);
            },
            options() {
                return this.$store.getters.options;
            },
            scoreList() {
                if (this.param) {

                    return scoreListFn(this.param);
                }
            },
            param() {
                if (this.epreuve.bareme) {

                    return this.epreuve.bareme.param;
                }
            },
            isEquipe() {
                return !!this.epreuve.equipe;
            },
            engagementOrder() {
                if (!this.epreuve.engagement) return [];
                return this.epreuve.engagement.sort((a, b) => {

                    return classementFn(a) - classementFn(b);

                })
            },
            equipeOrder() {
                if (!this.epreuve.equipe) return [];
                return this.epreuve.equipe.sort((a, b) => {
                    return a.classement - b.classement;
                })
            }
        },
        created() {
            // this.loader = true;


        }
    }

</script>

<style lang="scss">
    @import "../variables";


    .empty{
        color: #666;
        text-align: center;
        opacity: 0.7;
        button{
            margin-top: 1rem;
        }
    }
    .table {
        width: 100%;

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        th, td {
            padding: 5px;
            text-align: left;

            img {
                height: 1.2em;
                width: 1.2em;
            }
        }

        th {
            font-weight: 600;
        }

        tr {
            min-height: 15px;
        }

        .details {
            text-align: center;
        }

    }

    .classement {
        max-height: 1.5em;
        max-width: 1.5em;
    }

</style>