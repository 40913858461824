<template>
    <div class="refresh" :class="{validate: refreshOK}" :style="{top: refreshTop + 'px'}" v-show="showRefresh"><img
            src="icon/reload.svg"></div>
</template>

<script>
    export default {
        name: 'refresh',
        data() {
            return {
                showRefresh: false,
                refreshTop: 0,
                refreshOK: 0,
            }
        },
        computed: {
            loader() {
                return this.$store.state.showLoader;
            },
        },
        created() {
            let _startY;
            const inbox = document.querySelector('#app');

            inbox.addEventListener('touchstart', e => {
                _startY = e.touches[0].pageY;
                this.showRefresh = true
                this.refreshTop = -50
            }, {passive: true});

            inbox.addEventListener('touchmove', e => {
                const y = e.touches[0].pageY;
                // Activate custom pull-to-refresh effects when at the top of the container
                // and user is scrolling up.
                this.refreshTop = (y - _startY) - 50;
                if (document.scrollingElement.scrollTop === 0 && y > _startY + 150 &&
                    !this.loader) {
                    this.refreshOK = true;
                } else {
                    this.refreshOK = false;
                }
            }, {passive: true});

            inbox.addEventListener('touchend', e => {

                this.refreshTop = -50;
                this.showRefresh = false;
                if (this.refreshOK) {
                    this.refreshOK = false;
                    if (this.$route.params.num) {
                        this.$store.dispatch('loadConc', this.$route.params.num);//TODO refresh list conc

                    } else {

                        this.$store.dispatch('loadConcList');//TODO refresh list conc
                    }
                }

            }, {passive: true});
        }
    }


</script>

<style lang="scss">
    @import "../variables";
    .refresh{
        position: fixed;
        z-index: $header-z-index - 1;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0px 1px 5px black;
        transition:  background-color 200ms ease-in-out;
        img{
            transition:  all 1s ease-in-out;
            height: 40px;
            width: 40px;
        }

        &.validate {
            background-color: rgb(40, 199, 34);
            img{

                transform: rotate(950deg);
            }
        }
    }

</style>