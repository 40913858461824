<template>
    <div v-if="concSelect">
        <header>
            <!--<button v-if="selected" @click="changeConcours()" title="Changer de concours">-->
            <!--<img src="icon/package.svg" style="color: #ffffff">-->
            <!--</button>-->
            <button @click="menu()" class="btn-icon">
                <img src="icon/menu.svg"></button>
            <div class="title">
                <h1>{{concSelect.json.ffe.concours[0].$.nom}} <span v-if="epreuveNum"> <br> {{epreuve.$.nom_categorie}} ({{epreuve.$.num}})</span></h1>
            </div>
            <button @click="optionsMenu()" v-if="concSelect" class="btn-icon"><img src="icon/options.svg"></button>
        </header>
        <div class="epreuves">


            <nav :class="{'open': openMenu}">
                <div class="title">
                    <div @click="closeEpreuve()">
                        <div class="discipline"
                             :style="{'background-image': 'url(\'icon/discipline/' + concSelect.json.ffe.concours[0].$.nom_discipline +'.png\')'}">
                        </div>
                        <span>{{concSelect.json.ffe.concours[0].$.nom}}<br>
                            <span class="infos">Mis à jour le {{concSelect.last_update | date('LLL')}}</span></span>
                    </div>
                    <router-link to="/" tag="button" class="btn">Changer de concours</router-link>
                    <button class="btn-icon" @click="loadConc(true)"><img src="icon/reload.svg"></button>
                </div>
                <epreuves-list :active="epreuveNum" @change="changeEpreuve()" no-margin></epreuves-list>
                <!--<ul>-->
                    <!--<li tag="li" v-for="epre in epreuvesList" -->
                                 <!--:class="{'active': epre.$.num == epreuveNum}">-->
                        <!--{{epre.$.categorie}}-->
                        <!--({{epre.$.num}})-->
                    <!--</li>-->
                <!--</ul>-->
            </nav>

            <div class="options" :class="{'open': openOptions}">
                <div class="row">
                    <h3>Options d'affichages</h3>
                </div>
                <div class="row">
                    <label class="switch">
                        <input type="checkbox" id="classementPosition" v-model="options.classementPosition">
                        <span class="slider round"></span>

                    </label>
                    <label for="classementPosition">Afficher le classement en début de ligne</label>
                </div>
                <div class="row">
                    <label class="switch">
                        <input type="checkbox" id="afficheDetail" v-model="options.afficheDetail">
                        <span class="slider round"></span>

                    </label>
                    <label for="afficheDetail">Afficher les détails</label>
                </div>


            </div>
            <router-view/>

            <div class="infos footer">
                <span>Mis à jour le {{concSelect.last_update | date('LLL')}}</span>
            </div>
        </div>
        <div class="overlay" v-if="openMenu || openOptions" @click="overlayClick()"></div>


    </div>
</template>

<script>

    import EpreuvesList from '../components/EpreuvesList';

    export default {
        name: 'Concours',
        components:{
            EpreuvesList
        },
        data() {
            return {
                openMenu: false,
                openOptions: false,
                loader: true,
            }
        },
        computed: {
            concSelect() {
                return this.$store.getters.concours;
            },
            epreuvesList() {
                return this.$store.getters.epreuvesList;
            },
            options() {
                return this.$store.getters.options;
            },
            epreuveNum() {
                return this.$route.params.ep;
            },
            epreuve() {
                return this.$store.getters.epreuve(this.epreuveNum)
            },

        },
        methods: {
            loadConc() {
                this.openMenu = false;
                this.$store.dispatch('loadConc', this.$route.params.num);
            },
            menu() {
                this.openMenu = !this.openMenu;
            },
            optionsMenu() {
                this.openOptions = !this.openOptions;
            },
            overlayClick() {
                this.openMenu = false;
                this.openOptions = false;

            },
            closeEpreuve(){
                this.$router.push('/'+ this.$route.params.num);
                this.openMenu = false;
            },
            changeEpreuve(){
                this.openMenu = false;
            }
        },

        created() {
            this.loadConc();
        }
    }
</script>

<style lang="scss">

    @import "../variables";


    header {
        position: fixed;
        top: 0;
        width: 100vw;
        height: $header-height;
        background-color: $blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //font-family: stayclassy;
        z-index: $header-z-index;
        color: white;
        .title{
            text-align: center;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            h1{
                text-align: left;
            }
        }
        button {
            font-size: 1.2em;
        }
    }

    .epreuves {

        .options {
            min-height: 100vh;
            max-height: 100vh;
            height: 100vh;
            position: fixed;
            right: 0;
            transform: translateX(100%);
            transition: all 200ms ease;
            background-color: white;
            max-width: 80vw;
            min-width: 80vw;
            z-index: $header-z-index + 2;
            top: 0;
            padding: 1rem;
            overflow: scroll;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
            display: flex;
            flex-direction: column;

            &.open {
                transform: translateX(0%);

            }

            .row {
                display: flex;
                align-items: center;

            }

            h3 {
                width: 100%;
                text-align: center;
            }

            .switch + label {
                margin-left: 1em;
            }
        }

        .row + .row {
            margin-top: 1rem;
        }

        nav {
            min-height: 100vh;
            max-height: 100vh;
            height: 100vh;
            position: fixed;
            left: 0;
            transform: translateX(-100%);
            transition: all 200ms ease;
            background-color: white;
            max-width: 80vw;
            min-width: 80vw;
            z-index: $header-z-index + 2;
            top: 0;
            overflow: scroll;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);

            &.open {
                transform: translateX(0%);
            }

            .title {

                border-bottom: solid 1px gray;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;


                & > div {
                    padding: 1rem;

                }

                button {
                    // border: none;
                    // background-color: transparent;
                    // text-decoration: underline;
                    margin-left: 5px;
                    margin-bottom: 10px;
                    padding: 0.5rem;
                }

                .btn-icon {
                    font-size: 1.5em;
                }
            }

            ul {
                padding-bottom: 3rem;

                li {
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 1em;
                    padding: 1rem;
                    border-left: 3px solid transparent;
                    //&:nth-child(even) {
                    //  background-color: #0090d9;
                    //}
                    //&:nth-child(odd) {
                    //  background-color: rgba(239, 248, 255, 0.2);
                    //}
                    &.active {

                        color: white;
                        border-left: 3px solid deeppink;
                        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
                        background-color: $blue;
                    }
                }
            }

        }

        .infos.footer {
            position: fixed;
            bottom: 0;
            padding: 5px;
            background-color: rgba(255, 255, 255, .9);
            left: 0;
            right: 0;
        }
    }


    .infos {
        color: rgba(0,0,0,.8);
        text-align: center;
        font-size: 0.8em;
    }


    .overlay {
        height: 100vh;
        width: 100vw;
        position: fixed;
        background-color: rgba(0, 0, 0, .5);
        transition: all 200ms;
        z-index: $header-z-index + 1;
        top: 0;
        left: 0;
    }

    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        min-width: 60px;
        max-width: 60px;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        display: none;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: $blue;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $blue;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }


    .btn-icon {
        border: none;
        background-color: transparent;
        img {
            height: 1em;
            width: 1em;
            padding: 0.2em;
        }
    }

</style>
