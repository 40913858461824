<template>

    <ul :class="{'margin': !noMargin}">
        <li tag="li" v-for="epre in epreuvesList" @click="changeEpreuve(epre)" :class="{'active': epre.$.num == active}">
            <div style="display: flex; align-items: baseline; justify-content: space-between">
            {{epre.$.categorie}}
            ({{epre.$.num}})

            <span style="float: right; opacity: 0.7;font-size: 0.8em">{{restant(epre) | status(engas(epre).length)}} <img src='img/badge.svg' v-if="restant(epre)  == 0 && engas(epre).length >0" width='20px'></span>
            </div>
            <div class="progress"><div class="bar" :style="{width: progress(epre) + '%'}"></div></div>
        </li>
    </ul>
</template>

<script>

    export default {
        props:{
            active: String,
            noMargin: Boolean
        },
        computed: {
            epreuvesList() {
                return this.$store.getters.epreuvesList;
            },
            restant(){
                return (epreuve) => {
                    return this.engas(epreuve).filter(enga => !enga.classement).length;
                }
            },
            progress(){
                return (epreuve) => {
                    return this.engas(epreuve).filter(enga => enga.classement).length *100 / this.engas(epreuve).length ;
                }
            },
            engas(){
                return (epreuve) => {
                    let engas = epreuve.engagement ?  epreuve.engagement : epreuve.equipe;
                    if(!engas) return [];
                    return engas
                }
            }
        },
        filters: {
            status(restant, partants) {
                if(partants == 0){
                    return "Aucun partants"
                }
                if(restant === partants){
                    return partants + " partants";
                }
                if(restant === 0){
                    return "";
                }
                if(restant < partants){
                    return "En cours ("+ restant +"/"+ partants + ")";
                }

            }
        },
        methods:{
            changeEpreuve(epre){
                this.$emit('change', epre);
                this.$router.push('/'+ this.$route.params.num+ '/epreuve/' + epre.$.num);
            }
        }
    }

</script>

<style lang="scss">

@import "../variables";
    ul {
        &.margin{
        margin-top: $header-height;

        }
        padding-bottom: 3rem;

        li {
            width: 100%;
            box-sizing: border-box;
            font-size: 1em;
            padding: 1rem;
            border-left: 3px solid transparent;
            //&:nth-child(even) {
            //  background-color: #0090d9;
            //}
            //&:nth-child(odd) {
            //  background-color: rgba(239, 248, 255, 0.2);
            //}

        }
    }

    .progress{
        height: 0.2rem;
        width: 100%;
        .bar{
            background-color: $blue;
            width: 0;
            height: 100%;
        }
    }

</style>