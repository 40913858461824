<template>
  <div id="app">
<refresh></refresh>
    <router-view/>

    <loader></loader>
  </div>
</template>

<script>
  import refresh from './components/Refresh.vue'
  import loader from './components/Loader.vue'

  export default {
    data(){
      return {

      }
    },
    components:{
        refresh,
      loader
    }
  }
</script>


<style lang="scss">
  @import "compass/reset";
  @import 'compass/css3';
  @import "compass/utilities/color/contrast";
  @import "compass/css3/appearance";
  @import url('https://fonts.googleapis.com/css?family=Roboto');

@import "variables";

  html {
    font-family: 'Roboto', sans-serif;

  }

  body {
    height: 100vh;
    width: 100vw;
    /* Disables pull-to-refresh but allows overscroll glow effects. */
    overscroll-behavior-y: contain;
    margin: 0;
    padding: 0;
  }

  #app {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: scroll;
  }


  content {
      margin-top: $header-height;
      padding-bottom: 3rem;
      min-height: calc(100vh - #{$header-height});
      display: inherit;
      box-sizing: border-box;
      &.fixe {
          overflow: hidden;
      }
  }

button.btn{
  align-self:center;
  background:transparent;
  padding:1rem 1rem;

  transition:all .5s ease;
  color:#41403E;

  outline:none;
  box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
  border:solid 3px #41403E;
  /*
  Above is shorthand for:
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius:15px 255px;
  */
  &:hover{
    box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);
  }
  &[disabled]{
    background-color:  rgba(65, 64, 62, 0.651)
  }
  & + & {
    margin:0 1rem;
  }
}
</style>
