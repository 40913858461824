import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import config from "./config";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({paths:['concoursList', 'concours']})],
    state: {
        concoursList: [],
        concours: null,
        options: {
            classementPosition: false,
            afficheDetail: false
        },
        showLoader:false,
        errorConnect: false
    },
    getters: {
        loader(state) {
            return state.showLoader;
        },
        concoursList(state) {
            return state.concoursList.sort((a, b) => new Date(b.last_update) - new Date(a.last_update));
        },
        concours(state) {
            return state.concours;
        },
        options(state) {
            return state.options;
        },
        epreuvesList(state, getters) {
            if (getters.concours) {
                return getters.concours.json.ffe.concours[0].epreuve;
            }

        },
        epreuve(state, getters) {
            return (num) => {
                return getters.concours.json.ffe.concours[0].epreuve.find(el => el.$.num == num); //Come from URL is string
            };

        },
    },
    mutations: {
        concoursList(state, concs) {

            state.concoursList = concs;
        },
        concours(state, conc) {
            if(typeof conc.json === "string"){
                conc.json = JSON.parse(conc.json)
            }
            state.concours = conc;
        },
        options(state, options) {
            state.options = {...state.options, ...options};
        },
        loader(state, status){
          state.showLoader = status;
        },
        errorConnect(state, status){
            state.errorConnect = status;
        }
    },
    actions: {
        loadConcList(context) {
            context.commit('loader', true);
            context.commit('errorConnect', false);
            let url = config.service;

            let type = window.location.host.split('.');
            if (type[1] && type[1]!== "equilibre-online") {
                url = url + '?type=' + type[1];
            }

            axios.get(url).then((respond) => {
                context.commit('concoursList', respond.data);

            }).catch(e => {
                context.commit('errorConnect', true);

            }).finally(e => {
                context.commit('loader', false);
                console.log('loader');
            })

        },
        loadConc(context, num) {
            context.commit('loader', true);
            context.commit('errorConnect', false);
            let url = config.service;
            axios.get(url + '/' + num).then((respond) => {
                context.commit('concours', respond.data);
            }).catch(e => {
                context.commit('errorConnect', true);

            }).finally(e => {
                context.commit('loader', false);
                console.log('loader');
            })

        }
    }
})
